<template>
  <v-card rounded="lg" class="my-1">
    <v-card-title>
      Question {{ index+1 }}
      <v-spacer/>
      <v-btn icon>
        <v-icon color="blue" @click="editQuestion">mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon color="red lighten-1" @click="deleteQuestion(question)">mdi-delete</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      Mark {{ question.mark }}
    </v-card-subtitle>
    <v-img v-if="question.photo" :src="question.photo" alt="question photo"/>
    <v-card-text v-html="question.question"/>
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "ShowQuestion",
  props: ['question', 'index'],
  methods: {
    editQuestion() {
      this.$emit('editQuestion', this.question)
    },
    deleteQuestion(question) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/question/' + question.id;
          axios.delete(url).then(() => {
            this.$emit('updated');
            Swal.fire(
                'Deleted!',
                '',
                'success'
            )
          }).catch(() => {
            Swal.fire(
                'Failed!',
                'Something went wrong, try again',
                'warning'
            )
          })
        }
      });
    },
  }
}
</script>

<style scoped>

</style>