<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-row justify="center">
    <edit-written-exam v-if="!showEditButton" :course="course"></edit-written-exam>
    <v-snackbar top
                v-model="snackbar" :color="color"
                timeout="2000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" class="px-10">
      <v-dialog
          v-model="dialog"
          max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              @click="createQuestion"
          >
            Create new Question
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-center">
            <span class="headline">Question Details</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <form @submit.prevent="takeAction">
                <v-row>
                  <!--Question-->
                  <v-col
                      cols="12"
                  >
                    <quill-editor
                        :options="{placeholder: 'Enter question'}"
                        v-model="question"
                    ></quill-editor>
                  </v-col>

                  <!--Photo-->
                  <v-col
                      cols="12"
                  >
                    <v-file-input
                        chips
                        label="Choose question photo"
                        v-model="photo"
                    ></v-file-input>
                  </v-col>

                  <!--Answer -->
                  <v-col
                      cols="12"
                  >
                    <quill-editor
                        :options="{placeholder: 'Enter answer'}"
                        v-model="answer"
                    ></quill-editor>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field type="number" v-model="mark" label="Mark"/>
                  </v-col>


                  <v-btn
                      class="mr-4"
                      type="submit"
                      :loading="loading"
                  >
                    {{ editMode ? 'update' : 'submit' }}
                  </v-btn>
                  <v-btn @click="clear">
                    clear
                  </v-btn>
                </v-row>
              </form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn
          v-if="showEditButton"
          @click="showEditButton = false"
          class="mx-2"
          fab
          dark
          color="cyan"
          small
      >
        <v-icon dark>
          mdi-pencil
        </v-icon>
      </v-btn>
      <v-btn
          v-else
          @click="showEditButton = true"
          class="mx-2"
          fab
          dark
          color="red"
          small
      >
        <v-icon dark>
          mdi-close
        </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12">
      <show-question v-for="(question, index) in questions" :index="index" :question="question" :key="question.id"
                     @updated="loadQuestions"
                     @editQuestion="edit">
      </show-question>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'
import EditWrittenExam from "@/components/admin/course/EditWrittenExam";
import ShowQuestion from "@/components/admin/course/ShowQuestion";

export default {
  props: ['course'],
  components: {
    ShowQuestion,
    EditWrittenExam,
    quillEditor,
  },
  data: () => ({
    showEditButton: true,
    loading: false,
    questions: [],
    selectedQuestion: null,
    editMode: false,
    dialog: false,
    question: '',
    mark: '',
    answer: '',
    photo: null,
    snackbar: false,
    text: '',
    color: 'blue',
  }),
  computed: {
    writtenExamId: function () {
      return this.$route.params.writtenExamId;
    },
    form: function () {
      let formData = new FormData();
      formData.append('written_exam_id', this.$route.params.writtenExamId);
      if (this.editMode) {
        formData.append('_method', 'PUT');
      }
      formData.append('question', this.question);
      formData.append('answer', this.answer);
      formData.append('mark', this.mark)
      if (this.photo) {
        formData.append('photo', this.photo);
      }
      return formData;
    },
  },
  methods: {
    takeAction() {
      this.editMode ? this.update() : this.submit();
    },
    createQuestion() {
      this.editMode = false;
      this.selectedQuestion = null;
      this.question = '';
      this.answer = '';
      this.mark = '';
      this.photo = null;
    },
    edit(question) {
      this.selectedQuestion = question;
      this.dialog = true;
      this.editMode = true;
      this.question = question.question;
      this.answer = question.answer;
      this.mark = question.mark
      this.photo = null;
    },
    submit() {
      const url = 'admin/question';
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'New question created';
        this.loadQuestions();
        this.dialog = false;
      })
    },
    update() {
      const url = 'admin/question/' + this.selectedQuestion.id;
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'Question updated';
        this.loadQuestions();
        this.dialog = false;
      })
    },
    loadQuestions() {
      const url = 'admin/question?id=' + this.$route.params.writtenExamId;
      axios.get(url).then((response) => {
        this.questions = response.data.questions;
      })
    },
    clear() {
      this.editMode = false;
      this.question = '';
      this.answer = '';
      this.mark = ''
      this.photo = null;
    },
  },
  mounted() {
    this.loadQuestions();
  }
}
</script>